import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { TableDetailProps } from './table-detail.props';


export const TableDetailComponent = ({
  handbk,
}: TableDetailProps) => {
  return (
    <TableContainer>
      <Table aria-label="handbk-remote">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Название</TableCell>
            <TableCell>Описание</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell component="th" scope="row">{handbk.id}</TableCell>
          <TableCell>{handbk.name}</TableCell>
          <TableCell>{handbk.description}</TableCell>
        </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}