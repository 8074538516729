import { forwardRef, useState } from 'react';

import { Dialog, DialogContent, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { useAppSelector } from '@hooks/redux.hooks';
import { CardDataBlock } from '@blocks/card-data';
import { ButtonEditBlock } from '@blocks/button-edit';
import { ButtonCancelBlock } from '@blocks/button-cancel';

import { HeaderComponent } from './components/header';
import { VariantListComponent } from './components/variant-list';
import { TableDetailComponent } from './components/table-detail';
import { FormUpdateComponent } from './components/form-update';
import { VariantCreateComponent } from './components/variant-create';
import { VariantUpdateComponent } from './components/variant-update';

import { HandbkRemoteDetailProps } from './handbk-remote-detail.props';
import styles from './handbk-remote-detail.module.scss';
import { HandbkRemoteVariantType } from '@type/handbk-remote/handbk-remote-variant.type';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const HandbkRemoteDetailFeature = ({
  handbkId,
  closeDialog,
}: HandbkRemoteDetailProps) => {
  const [isEdited, setIsEdited] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState<HandbkRemoteVariantType | null>(null);
  const [createFormOpened, setCreateFormOpened] = useState(false);
  const handbk = useAppSelector((s) => s.handbkRemote.list.find((h) => h.id === handbkId));

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      open={!!handbkId}
      onClose={closeDialog}
    >
      <div className={styles['handbk-detail']}>
        <HeaderComponent onClose={closeDialog} />
        <DialogContent>
          {handbk && (<>
            <div className="container">
              <div className={styles['handbk-detail__main-info']}>
                <CardDataBlock
                  title="Удаленный справочник"
                  extra={(<>
                    {isEdited ? (
                      <ButtonCancelBlock onClick={() => setIsEdited(false)} />
                    ) : (<>
                      <ButtonEditBlock onClick={() => setIsEdited(true)} />
                    </>)}
                  </>)}
                >
                  {isEdited ? (
                    <FormUpdateComponent handbk={handbk} closeEditor={() => setIsEdited(false)} />
                  ) : (
                    <TableDetailComponent handbk={handbk} />
                  )}
                </CardDataBlock>
              </div>
              <VariantListComponent
                handbk={handbk}
                openCreateDialog={() => setCreateFormOpened(true)}
                openUpdateDialog={(variant) => setSelectedVariant(variant)}
              />
            </div>
            <VariantCreateComponent
              handbkId={handbk.id}
              opened={createFormOpened}
              closeDialog={() => setCreateFormOpened(false)}
            />
            <VariantUpdateComponent
              handbkVariant={selectedVariant}
              closeDialog={() => setSelectedVariant(null)}
            />
          </>)}
        </DialogContent>
      </div>
    </Dialog>
  );
}
