import { AppBar, IconButton, Toolbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { HeaderProps } from './header.props';


export const HeaderComponent = ({
  onClose,
}: HeaderProps) => (
  <AppBar sx={{ position: 'relative' }}>
    <Toolbar>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
    </Toolbar>
  </AppBar>
)