import { HttpService } from '@services/http.service';
import { HandbkRemoteCreateRequest } from '@type/handbk-remote/handbk-remote-create.req';
import { HandbkRemoteCreateResponse } from '@type/handbk-remote/handbk-remote-create.res';
import { HandbkRemoteListResponse } from '@type/handbk-remote/handbk-remote-list.res';
import { HandbkRemoteSortableRequest } from '@type/handbk-remote/handbk-remote-sortable.req';
import { HandbkRemoteSortableResponse } from '@type/handbk-remote/handbk-remote-sortable.res';
import { HandbkRemoteUpdateRequest } from '@type/handbk-remote/handbk-remote-update.req';
import { HandbkRemoteUpdateResponse } from '@type/handbk-remote/handbk-remote-update.res';
import { HandbkRemoteVariantCreateRequest } from '@type/handbk-remote/handbk-remote-variant-create.req';
import { HandbkRemoteVariantCreateResponse } from '@type/handbk-remote/handbk-remote-variant-create.res';
import { HandbkRemoteVariantUpdateRequest } from '@type/handbk-remote/handbk-remote-variant-update.req';
import { HandbkRemoteVariantUpdateResponse } from '@type/handbk-remote/handbk-remote-variant-update.res';
import { BaseResponseInterface } from '@type/shared/base-response.interface';


export class HandbkRemoteApi {
  public static async create(
    payload: HandbkRemoteCreateRequest,
  ): Promise<BaseResponseInterface<HandbkRemoteCreateResponse>> {
    return await HttpService.sendRequest('POST', '/handbk-remote/create', {
      handbk: payload,
    });
  };

  public static async list(): Promise<BaseResponseInterface<HandbkRemoteListResponse>> {
    return await HttpService.sendRequest('GET', '/handbk-remote/list');
  };
  
  public static async update(
    payload: HandbkRemoteUpdateRequest,
  ): Promise<BaseResponseInterface<HandbkRemoteUpdateResponse>> {
    return await HttpService.sendRequest('PUT', '/handbk-remote/update', {
      handbk: payload,
    });
  };

  public static async sortable(
    payload: HandbkRemoteSortableRequest,
  ): Promise<BaseResponseInterface<HandbkRemoteSortableResponse>> {
    return await HttpService.sendRequest('PUT', '/handbk-remote/sortable', {
      handbk: payload,
    });
  };

  public static async variantCreate(
    payload: HandbkRemoteVariantCreateRequest,
  ): Promise<BaseResponseInterface<HandbkRemoteVariantCreateResponse>> {
    return await HttpService.sendRequest('POST', '/handbk-remote/variant-create', {
      handbkVariant: payload,
    });
  };

  public static async variantUpdate(
    payload: HandbkRemoteVariantUpdateRequest,
  ): Promise<BaseResponseInterface<HandbkRemoteVariantUpdateResponse>> {
    return await HttpService.sendRequest('PUT', '/handbk-remote/variant-update', {
      handbkVariant: payload,
    });
  };
};
