import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthStateInterface } from '@type/auth/auth-state.interface';
import { RoleType } from '@type/role/role.type';
import { BackendErrorsType } from '@type/shared/backend-errors.type';

import { currentUserAction, signinAction, signoutAction } from './auth.actions';


const initialState: AuthStateInterface = {
  isLoggedIn: false,
  user: null,
  status: 'idle',
  errors: null,
}

const authSlice = createSlice({
  name: '@@auth',
  initialState,
  reducers: {
    updateCurrentUserRoleAfterUpdateAction(state, action: PayloadAction<RoleType>) {
      if (state.user?.role.id === action.payload.id) {
        state.user.role = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signinAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(signinAction.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.status = 'idle';
        state.user = action.payload;
      })
      .addCase(signinAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      })
      .addCase(currentUserAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(currentUserAction.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.status = 'idle';
        state.user = action.payload;
      })
      .addCase(currentUserAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      })
      .addCase(signoutAction.pending, (state) => {
        state.user = null;
        state.isLoggedIn = false;
      });
  },
});

export default authSlice.reducer;

export const {
  updateCurrentUserRoleAfterUpdateAction,
} = authSlice.actions;
