import { useEffect } from 'react';

import { LayoutBaseFeature } from '@features/layout-base';
import { HandbkRemoteListFeature } from '@features/handbk-remote-list';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { setBreadcrumbs } from '@stores/app/app.slice';
import { CardDataBlock } from '@blocks/card-data';
import { HandbkRemoteCreateFeature } from '@features/handbk-remote-create';
import { handbkRemoteListAction } from '@stores/handbk-remote/handbk-remote.actions';


const HandbkRemoteListPage = () => {
  const dispatch = useAppDispatch();
  const hanbkRemoteState = useAppSelector((s) => s.handbkRemote);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Удаленные справочники', link: '' },
    ]));

    dispatch(handbkRemoteListAction());
  }, [dispatch]);

  return (
    <LayoutBaseFeature>
      <div className="row">
        <div className="col-sm-12">
          <CardDataBlock
            title="Удаленные справочники"
            extra={<HandbkRemoteCreateFeature />}
            loading={hanbkRemoteState.status === 'loading'}
            noData={hanbkRemoteState.list.length === 0}
          >
            <HandbkRemoteListFeature />
          </CardDataBlock>
        </div>
      </div>
    </LayoutBaseFeature>
  );
};

export { HandbkRemoteListPage };
