import { WidgetLinkProps } from './widget-link.props';
import styles from './widget-link.module.scss';


const WidgetLinkBlock = ({
  title,
  icon,
  onClick,
}: WidgetLinkProps) => {
  return (
    <div
      className={styles['widget-link']}
      onClick={onClick}
    >
      <div className={styles['widget-link__title']}>{title}</div>
      <div className={styles['widget-link__icon']}>{icon}</div>
    </div>
  );
};

export { WidgetLinkBlock };
