import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { dateTranslate } from '@helpers/date-translate.helper';
import { useAppSelector } from '@hooks/redux.hooks';


const UserDetailFeature = () => {
  const user = useAppSelector((state) => state.user);

  return (
    <TableContainer>
      <Table aria-label="user-detail">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Пользователь</TableCell>
            <TableCell>Электронная почта</TableCell>
            <TableCell>Роль</TableCell>
            <TableCell>Дата создания</TableCell>
            <TableCell>Дата обновления</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {user.current!.id}
              </TableCell>
              <TableCell>{user.current!.firstName} {user.current!.secondName} {user.current!.lastName}</TableCell>
              <TableCell>{user.current!.email}</TableCell>
              <TableCell>{user.current!.role.name}</TableCell>
              <TableCell>{dateTranslate(user.current!.createdAt)}</TableCell>
              <TableCell>{dateTranslate(user.current!.updatedAt)}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { UserDetailFeature };
