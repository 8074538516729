import { SyntheticEvent, useEffect, useState } from 'react';

import { Dialog, DialogContent } from '@mui/material';

// import { handbkVariantUpdateAction } from '@stores/handbk-remote/handbk-remote.actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { CardHeaderBlock } from '@blocks/card-header';
import { ButtonCancelBlock } from '@blocks/button-cancel';
import { FieldTextBlock } from '@blocks/field-text';
import { ButtonsBlock } from '@blocks/buttons';
import { ButtonSaveBlock } from '@blocks/button-save';
import { errorTranslate } from '@helpers/error-translate.helper';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';

import { VariantUpdateProps } from './variant-update.props';
import { handbkRemoteVariantUpdateAction } from '@stores/handbk-remote/handbk-remote.actions';


export const VariantUpdateComponent = ({
  handbkVariant,
  closeDialog,
}: VariantUpdateProps) => {
  const initialformValue = {
    label: '',
  };

  const dispatch = useAppDispatch();
  const handbkRemoteState = useAppSelector((s) => s.handbkRemote);

  const [formDirty, setFormDirty] = useState(false);
  const [sendedForm, setSendedForm] = useState(false);
  const [formValue, setFormValue] = useState(initialformValue);

  useEffect(() => {
    if (handbkVariant) {
      setFormValue({ label: handbkVariant.label });
    }

  }, [handbkVariant])

  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const closeForm = () => {
    closeDialog();
    setFormValue(initialformValue);
    setSendedForm(false);
    setFormDirty(false);
  }

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();
    
    setSendedForm(true);

    const result = await dispatch(handbkRemoteVariantUpdateAction({
      id: handbkVariant!.id,
      label: formValue.label,
    }));

    if (result.type === '@@handbk-remote/variant-update/fulfilled') {
      closeForm();
    }
  }

  const setError = (fieldName: string) => {
    return sendedForm
      ? handbkRemoteState.errors && handbkRemoteState.errors[fieldName] && errorTranslate(handbkRemoteState.errors[fieldName][0])
      : '';
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={!!handbkVariant}
      onClose={closeDialog}
    >
      <CardHeaderBlock
        title="Редактировать вариант"
        extra={<ButtonCancelBlock onClick={closeForm} />}
      />
      <DialogContent>
        <div className="row justify-content-lg-center">
          <div className="col col-lg-6">
            <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
              <FieldTextBlock
                name="label"
                label="Значение"
                value={formValue.label}
                onChange={changeFormValue}
                error={setError('label')}
                required
              />
              <ButtonsBlock>
                <ButtonSaveBlock
                  loading={handbkRemoteState.status === 'submitting'}
                  onClick={onSubmit}
                  disabled={!formDirty}
                />
                <ButtonCancelBlock onClick={closeForm} />
              </ButtonsBlock>
            </form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
