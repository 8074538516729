import { TextField } from '@mui/material';

import { FormFieldBlock } from '@blocks/form-field';
import { AsteriskBlock } from '@blocks/asterisk';

import { FieldLongTextProps } from './field-long-text.props';


const FieldLongTextBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
}: FieldLongTextProps) => {
  return (
    <FormFieldBlock error={error} prompt={prompt}>
      <TextField
        label={<>{label}<AsteriskBlock required={required} counted={counted} /></>}
        name={name}
        value={value}
        onChange={onChange}
        type="text"
        variant="filled"
        fullWidth
        multiline
        rows={4}
      />
    </FormFieldBlock>
  );
};

export { FieldLongTextBlock };
