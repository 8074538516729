import { SyntheticEvent, useState } from 'react';

import { Dialog, DialogContent, Divider, IconButton } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';

import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { errorTranslate } from '@helpers/error-translate.helper';
import { ButtonSaveBlock } from '@blocks/button-save';
import { ButtonCancelBlock } from '@blocks/button-cancel';
import { FieldPasswordBlock } from '@blocks/field-password';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';

import styles from './user-change-password.module.scss';
import { changeUserPasswordAction } from '@stores/user/user.actions';


const UserChangePasswordFeature = () => {
  const initialFormValue = {
    password: '',
    passwordConfirm: '',
  };

  const [opened, setOpened] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const [formValue, setFormValue] = useState(initialFormValue);
  const [sendedForm, setSendedForm] = useState(false);

  const isSubmitting = useAppSelector((state) => state.user.status === 'submitting');
  const errors = useAppSelector((state) => state.user.errors);
  const userId = useAppSelector((state) => state.user.current?.id);

  const dispatch = useAppDispatch();

  const closeDialog = () => {
    setOpened(false);
    setFormDirty(false);
    setSendedForm(false);
    setFormValue(initialFormValue);
  };

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();
    setSendedForm(true);

    if (userId === undefined) return;

    const result = await dispatch(changeUserPasswordAction({
      password: formValue.password,
      id: userId,
    }));

    if (result.type === '@@user/change-user-password/fulfilled') {
      closeDialog();
    }
  };

  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const setError = (fieldName: string) => {
    return sendedForm
      ? errors && errors[fieldName] && errorTranslate(errors[fieldName][0])
      : '';
  };

  const isDisabledSaveButton = !formDirty || (
    (formValue.password === '' || formValue.passwordConfirm === '') ||
    (formValue.password !== formValue.passwordConfirm)
  );

  return (
    <div className={styles['user-change-password']}>
      <IconButton onClick={() => setOpened(true)} aria-label="delete" size="large">
        <KeyIcon />
      </IconButton>
      <Dialog
        open={opened}
        onClose={() => setOpened(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
      >
        <div className={styles['user-change-password__title']}>
          <div>Изменить пароль</div>
          <div className={styles['user-change-password__buttons']}>
            <ButtonCancelBlock onClick={() => closeDialog()} />
          </div>
        </div>
        <Divider />
        <DialogContent>
          <div className="row justify-content-lg-center">
            <div className="col col-lg-6">
              <form
                className={styles['user-change-password__form']}
                onSubmit={(e: SyntheticEvent) => onSubmit(e)}
              >
                <FieldPasswordBlock
                  onChange={changeFormValue}
                  name="password"
                  label="Введите пароль"
                  value={formValue.password}
                  error={setError('password')}
                />
                <FieldPasswordBlock
                  onChange={changeFormValue}
                  name="passwordConfirm"
                  label="Повторите пароль"
                  value={formValue.passwordConfirm}
                  error={setError('passwordConfirm')}
                />
                <ButtonSaveBlock
                  loading={isSubmitting}
                  onClick={onSubmit}
                  disabled={isDisabledSaveButton}
                />
                <ButtonCancelBlock onClick={() => closeDialog()} />
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export { UserChangePasswordFeature };
