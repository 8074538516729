import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LayoutBaseFeature } from '@features/layout-base'
import { RoleDetailFeature } from '@features/role-detail';
import { RoleUpdateFeature } from '@features/role-update';
import { setBreadcrumbs } from '@stores/app/app.slice';
import { getRoleOneAction } from '@stores/role/role.actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { ButtonBackBlock } from '@blocks/button-back';
import { CardDataBlock } from '@blocks/card-data';
import { ButtonEditBlock } from '@blocks/button-edit';
import { ButtonCancelBlock } from '@blocks/button-cancel';
import { TransitionOpacityBlock } from '@blocks/transition-opacity';

import { RoleDetailPageParams } from './role-detail.page-params';


const RoleDetailPage = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role);
  const { id } = useParams<RoleDetailPageParams>();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Роли', link: '/role-list' },
      { title: 'Роль №' + id, link: '' },
    ]));

    dispatch(getRoleOneAction(Number(id)));
  }, [dispatch, id]);

  return (
    <LayoutBaseFeature>
      <CardDataBlock
        title={'Роль №' + id}
        extra={
          <>
            {isEditing
              ? (
                <ButtonCancelBlock onClick={() => setIsEditing(false)} />
              )
              : (
                <ButtonEditBlock onClick={() => setIsEditing(true)} />
              )
            }
            {!isEditing && <ButtonBackBlock />}
          </>
        }
        loading={role.status === 'loading'}
      >
        {isEditing && (
          <TransitionOpacityBlock>
            <RoleUpdateFeature onDone={() => setIsEditing(false)} />
          </TransitionOpacityBlock>
        )}
        {role.current && !isEditing && (
          <TransitionOpacityBlock>
            <RoleDetailFeature />
          </TransitionOpacityBlock>
        )}
      </CardDataBlock>
    </LayoutBaseFeature>
  );
};

export { RoleDetailPage };
