import { createAsyncThunk } from '@reduxjs/toolkit';

import { callNotifyAction } from '@stores/app/app.slice';

import { HandbkRemoteApi } from './handbk-remote.api';
import { HandbkRemoteCreateResponse } from '@type/handbk-remote/handbk-remote-create.res';
import { HandbkRemoteCreateRequest } from '@type/handbk-remote/handbk-remote-create.req';
import { HandbkRemoteStateInterface } from '@type/handbk-remote/handbk-remote-state.interface';
import { HandbkRemoteListResponse } from '@type/handbk-remote/handbk-remote-list.res';
import { HandbkRemoteUpdateResponse } from '@type/handbk-remote/handbk-remote-update.res';
import { HandbkRemoteUpdateRequest } from '@type/handbk-remote/handbk-remote-update.req';
import { HandbkRemoteVariantCreateResponse } from '@type/handbk-remote/handbk-remote-variant-create.res';
import { HandbkRemoteVariantCreateRequest } from '@type/handbk-remote/handbk-remote-variant-create.req';
import { HandbkRemoteVariantUpdateResponse } from '@type/handbk-remote/handbk-remote-variant-update.res';
import { HandbkRemoteVariantUpdateRequest } from '@type/handbk-remote/handbk-remote-variant-update.req';
import { HandbkRemoteSortableResponse } from '@type/handbk-remote/handbk-remote-sortable.res';
import { HandbkRemoteSortableRequest } from '@type/handbk-remote/handbk-remote-sortable.req';


export const handbkRemoteCreateAction = createAsyncThunk<
  HandbkRemoteCreateResponse,
  HandbkRemoteCreateRequest,
  { state: { handbkRemote: HandbkRemoteStateInterface } }
>(
  '@@handbk-remote/create',
  async (payload: HandbkRemoteCreateRequest, { rejectWithValue, dispatch }) => {
    const response = await HandbkRemoteApi.create(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { handbkRemote } = getState();

      if (handbkRemote.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const handbkRemoteListAction = createAsyncThunk<
  HandbkRemoteListResponse,
  undefined,
  { state: { handbkRemote: HandbkRemoteStateInterface } }
>(
  '@@handbk-remote/list',
  async (_, { rejectWithValue, dispatch }) => {
    const response = await HandbkRemoteApi.list();
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка чтения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { handbkRemote } = getState();

      if (handbkRemote.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const handbkRemoteUpdateAction = createAsyncThunk<
  HandbkRemoteUpdateResponse,
  HandbkRemoteUpdateRequest,
  { state: { handbkRemote: HandbkRemoteStateInterface } }
>(
  '@@handbk-remote/update',
  async (payload: HandbkRemoteUpdateRequest, { rejectWithValue, dispatch }) => {
    const response = await HandbkRemoteApi.update(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { handbkRemote } = getState();

      if (handbkRemote.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const handbkRemoteSortableAction = createAsyncThunk<
  HandbkRemoteSortableResponse,
  HandbkRemoteSortableRequest,
  { state: { handbkRemote: HandbkRemoteStateInterface } }
>(
  '@@handbk-remote/handbk-sortable',
  async (payload: HandbkRemoteSortableRequest, { rejectWithValue, dispatch }) => {
    const response = await HandbkRemoteApi.sortable(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { handbkRemote } = getState();

      if (handbkRemote.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const handbkRemoteVariantCreateAction = createAsyncThunk<
  HandbkRemoteVariantCreateResponse,
  HandbkRemoteVariantCreateRequest,
  { state: { handbkRemote: HandbkRemoteStateInterface } }
>(
  '@@handbk-remote/variant-create',
  async (payload: HandbkRemoteVariantCreateRequest, { rejectWithValue, dispatch }) => {
    const response = await HandbkRemoteApi.variantCreate(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { handbkRemote } = getState();

      if (handbkRemote.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const handbkRemoteVariantUpdateAction = createAsyncThunk<
  HandbkRemoteVariantUpdateResponse,
  HandbkRemoteVariantUpdateRequest,
  { state: { handbkRemote: HandbkRemoteStateInterface } }
>(
  '@@handbk-remote/variant-update',
  async (payload: HandbkRemoteVariantUpdateRequest, { rejectWithValue, dispatch }) => {
    const response = await HandbkRemoteApi.variantUpdate(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { handbkRemote } = getState();

      if (handbkRemote.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);
