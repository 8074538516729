import { useState } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { useAppSelector } from '@hooks/redux.hooks';
import { HandbkRemoteDetailFeature } from '@features/handbk-remote-detail';
import { HandbkRemoteType } from '@type/handbk-remote/handbk-remote.type';


const HandbkRemoteListFeature = () => {
  const [currentHandbk, setCurrentHandbk] = useState<HandbkRemoteType | null>(null);
  const handbkRemoteState = useAppSelector((s) => s.handbkRemote);

  return (
    <>
      <TableContainer>
        <Table aria-label="handbk-list">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Описание</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {handbkRemoteState.list.map((item) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={item.id}
              >
                <TableCell component="th" scope="row">
                  {item.id}
                </TableCell>
                <TableCell>
                  <div
                    style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}
                    onClick={() => setCurrentHandbk(item)}
                  >{item.name}</div>
                </TableCell>
                <TableCell>{item.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <HandbkRemoteDetailFeature
        handbkId={currentHandbk ? currentHandbk.id : null}
        closeDialog={() => setCurrentHandbk(null)}
      />
    </>
  );
};

export { HandbkRemoteListFeature };
