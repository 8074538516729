import { SyntheticEvent, useState } from 'react';

import { Dialog, DialogContent } from '@mui/material';

import { ButtonCancelBlock } from '@blocks/button-cancel';
import { ButtonCreateBlock } from '@blocks/button-create'
import { CardHeaderBlock } from '@blocks/card-header';
import { FieldTextBlock } from '@blocks/field-text';
import { errorTranslate } from '@helpers/error-translate.helper';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';
import { ButtonsBlock } from '@blocks/buttons';
import { ButtonSaveBlock } from '@blocks/button-save';
// import { handbkCreateAction } from '@stores/handbk-remote/handbk-remote.actions';
import { FieldLongTextBlock } from '@blocks/field-long-text';
import { handbkRemoteCreateAction } from '@stores/handbk-remote/handbk-remote.actions';


export const HandbkRemoteCreateFeature = () => {
  const initialformValue = {
    name: '',
    description: '',
  };

  const dispatch = useAppDispatch();
  const hanbkRemoteState = useAppSelector((s) => s.handbkRemote);

  const [opened, setOpened] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const [sendedForm, setSendedForm] = useState(false);
  const [formValue, setFormValue] = useState(initialformValue);


  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const closeForm = () => {
    setOpened(false);
    setFormValue(initialformValue);
    setSendedForm(false);
    setFormDirty(false);
  }

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();
    
    setSendedForm(true);

    const result = await dispatch(handbkRemoteCreateAction(formValue));

    if (result.type === '@@handbk-remote/create/fulfilled') {
      closeForm();
    }
  }

  const setError = (fieldName: string) => {
    return sendedForm
      ? hanbkRemoteState.errors && hanbkRemoteState.errors[fieldName] && errorTranslate(hanbkRemoteState.errors[fieldName][0])
      : '';
  }

  return (
    <>
      <ButtonCreateBlock onClick={() => setOpened(true)} />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={opened}
        onClose={() => setOpened(false)}
      >
        <CardHeaderBlock
          title="Добавить справочник"
          extra={<ButtonCancelBlock onClick={() => closeForm()} />}
        />
        <DialogContent>
          <div className="row justify-content-lg-center">
            <div className="col col-lg-6">
              <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
                <FieldTextBlock
                  name="name"
                  label="Название"
                  value={formValue.name}
                  onChange={changeFormValue}
                  error={setError('name')}
                  required
                />
                <FieldLongTextBlock
                  name="description"
                  label="Описание"
                  value={formValue.description}
                  onChange={changeFormValue}
                  error={setError('description')}
                />
                <ButtonsBlock>
                  <ButtonSaveBlock
                    loading={hanbkRemoteState.status === 'submitting'}
                    onClick={onSubmit}
                    disabled={!formDirty}
                  />
                  <ButtonCancelBlock onClick={() => closeForm()} />
                </ButtonsBlock>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
