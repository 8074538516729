import { FC } from 'react';
import cs from 'classnames';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { FormFieldProps } from './form-field.props';
import styles from './form-field.module.scss';
import { Tooltip } from '@mui/material';


const FormFieldBlock: FC<FormFieldProps> = ({ children, error, notError, prompt }) => {
  const formFieldClassname = cs(
    styles['form-field'],
    { [styles['form-field--not-error']]: notError },
  );

  return (
    <div className={formFieldClassname}>
      {prompt && (
        <div className={styles['form-field__prompt']}>
          <Tooltip title={prompt}>
            <HelpOutlineIcon style={{ opacity: '.5' }} />
          </Tooltip>
        </div>
      )}
      {children}
      {error && !notError && (
        <div className={styles['form-field__error']}>{error}</div>
      )}
    </div>
  )
}

export { FormFieldBlock };
