import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// import { handbkSortableAction } from '@stores/handbk-remote/handbk-remote.actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { CardDataBlock } from '@blocks/card-data';
import { ButtonCreateBlock } from '@blocks/button-create';

import { VariantListProps } from './variant-list.props';
import { VariantOneComponent } from '../variant-one';
import { handbkRemoteSortableAction } from '@stores/handbk-remote/handbk-remote.actions';


export const VariantListComponent = ({
  handbk,
  openCreateDialog,
  openUpdateDialog
}: VariantListProps) => {
  const dispatch = useAppDispatch();
  const handbkRemoteState = useAppSelector((s) => s.handbkRemote);

  const onDragEnd = async (e: any) => {
    if (
      e.over === null ||
      e.active.data.current.sortable.index === e.over.data.current.sortable.index
    ) return;

    const sortable = [...handbk.sortable];

    const sortableToUp = e.active.data.current.sortable.index > e.over.data.current.sortable.index;
    const dragableIndex = e.active.data.current.sortable.index;
    const endIndex = e.over.data.current.sortable.index;

    sortable.splice(endIndex + (sortableToUp ? 0 : 1), 0, sortable[dragableIndex]);
    sortable.splice(dragableIndex + (sortableToUp ? 1 : 0), 1);

    await dispatch(handbkRemoteSortableAction({
      id: handbk.id,
      sortable,
    }));
  };

  return (
    <CardDataBlock
      title="Варианты"
      extra={<ButtonCreateBlock onClick={openCreateDialog} />}
      noData={!(handbk && handbk.sortable.length > 0)}
    >
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext items={handbk.sortable}>
          <TableContainer>
            <Table aria-label="handbk-variant">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Значение</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {handbk.sortable.map((variantId) => {
                  const variant = handbkRemoteState.variants.find((v) => v.id === variantId);

                  if (!variant) return null;

                  return <VariantOneComponent openUpdateDialog={openUpdateDialog} key={variant.id} handbkVariant={variant} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </SortableContext>
      </DndContext>
    </CardDataBlock>
  );
}