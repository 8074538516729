import { useEffect } from 'react';
import cs from 'classnames';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import "moment/locale/ru";

import { NotifyFeature } from '@features/notify';
import { RouterOutletFeature } from '@features/router-outlet';
import { TopBarFeature } from '@features/top-bar';
import { AppLoaderFeature } from '@features/app-loader';
import { currentUserAction } from '@stores/auth/auth.actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';

import styles from './app.module.scss';


const AppComponent = (): JSX.Element => {
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(currentUserAction());
  }, [dispatch]);

  useEffect(() => {
    document.title = 'DCS';
  }, []);

  const appClassnames = cs(
    styles['app'],
    { [styles['app--is-logged-in']]: auth.isLoggedIn },
  );
  
  return (
    <div className={appClassnames}>
      <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale={'ru'}>
        {auth.isLoggedIn && <TopBarFeature />}
        <div className={styles['app__work-area']}>
          <RouterOutletFeature />
        </div>
        <NotifyFeature />
        <AppLoaderFeature />
      </LocalizationProvider>
    </div>
  );
};

export default AppComponent;
