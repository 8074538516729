import { TransitionOpacityBlock } from '@blocks/transition-opacity';
import { BreadcrumbsFeature } from '@features/breadcrumbs';

import { LayoutBaseProps } from './layout-base.props';


const LayoutBaseFeature = ({ children }: LayoutBaseProps) => {
  return (
    <>
      <div className="container">
        <BreadcrumbsFeature />
        <TransitionOpacityBlock>
          {children}
        </TransitionOpacityBlock>
      </div>
    </>
  );
}

export { LayoutBaseFeature };
