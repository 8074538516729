import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { RoleListPage } from '@pages/role-list';
import { RoleCreatePage } from '@pages/role-create';
import { RoleDetailPage } from '@pages/role-detail';
import { UserListPage } from '@pages/user-list';
import { UserCreatePage } from '@pages/user-create';
import { UserDetailPage } from '@pages/user-detail';
import { HandbkRemoteListPage } from '@pages/handbk-remote-list';
import { HomePage } from '@pages/home';
import { SigninPage } from '@pages/signin';
import { NotFoundPage } from '@pages/not-found';
import { AnonGuardHoc } from '@hocs/anon-guard';
import { AuthGuardHoc } from '@hocs/auth-guard';


const RouterOutletFeature = () => {

  const router = createBrowserRouter([
    { path: '/', element: <AuthGuardHoc><HomePage /></AuthGuardHoc> },
    { path: '/role-list', element: <AuthGuardHoc><RoleListPage /></AuthGuardHoc> },
    { path: '/role-list/role-create', element: <AuthGuardHoc><RoleCreatePage /></AuthGuardHoc> },
    { path: '/role-list/:id', element: <AuthGuardHoc><RoleDetailPage /></AuthGuardHoc> },
    { path: '/user-list', element: <AuthGuardHoc><UserListPage /></AuthGuardHoc> },
    { path: '/user-list/user-create', element: <AuthGuardHoc><UserCreatePage /></AuthGuardHoc> },
    { path: '/user-list/:id', element: <AuthGuardHoc><UserDetailPage /></AuthGuardHoc> },
    { path: '/handbk-remote-list', element: <AuthGuardHoc><HandbkRemoteListPage /></AuthGuardHoc> },
    { path: '/signin', element: <AnonGuardHoc><SigninPage /></AnonGuardHoc> },

    { path: '*', element: <NotFoundPage /> },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export { RouterOutletFeature };
