import { useNavigate } from 'react-router-dom';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import { WidgetLinkBlock } from '@blocks/widget-link';

import styles from './widgets-list.module.scss';


const WidgetsListFeature = () => {
  const navigate = useNavigate();

  return (
    <>
      {(
        <div className={styles['widgets-list']}>
          {/* <Typography variant="h5" className={styles['widgets-list__header']}>Системные</Typography> */}
          <div className={styles['widgets-list__items']}>
            <div className={styles['widgets-list__item']}>
              <WidgetLinkBlock
                title="Роли"
                onClick={() => navigate('/role-list')}
                icon={<AdminPanelSettingsIcon />}
              />
            </div>
            <div className={styles['widgets-list__item']}>
              <WidgetLinkBlock
                title="Пользователи"
                onClick={() => navigate('/user-list')}
                icon={<ManageAccountsIcon />}
              />
            </div>
            <div className={styles['widgets-list__item']}>
              <WidgetLinkBlock
                title="Удаленные справочники"
                onClick={() => navigate('/handbk-remote-list')}
                icon={<MenuBookIcon />}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export { WidgetsListFeature };
