import { useEffect } from 'react';

import { CardDataBlock } from '@blocks/card-data';
import { LayoutBaseFeature } from '@features/layout-base'
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { setBreadcrumbs } from '@stores/app/app.slice';
import { ButtonBackBlock } from '@blocks/button-back';
import { UserCreateFeature } from '@features/user-create';
import { getRoleDictionaryAction } from '@stores/role/role.actions';


const UserCreatePage = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => {
    return state.role.status === 'loading';
  });

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/user-list' },
      { title: 'Добавить пользователя', link: '' },
    ]))

    dispatch(getRoleDictionaryAction());
  }, [dispatch])

  return (
    <LayoutBaseFeature>
      <div className="row">
        <div className="col-sm-12">
          <CardDataBlock
            title="Добавить пользователя"
            extra={<ButtonBackBlock />}
            loading={loading}
          >
            <div className="row justify-content-lg-center">
              <div className="col col-lg-6">
                <UserCreateFeature />
              </div>
            </div>
          </CardDataBlock>
        </div>
      </div>
    </LayoutBaseFeature>
  );
};

export { UserCreatePage };
