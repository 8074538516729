import { SyntheticEvent, useState } from 'react';

import { Dialog, DialogContent } from '@mui/material';

import { CardHeaderBlock } from '@blocks/card-header';
import { ButtonCancelBlock } from '@blocks/button-cancel';
import { ButtonsBlock } from '@blocks/buttons';
import { ButtonSaveBlock } from '@blocks/button-save';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { errorTranslate } from '@helpers/error-translate.helper';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';

import { VariantCreateProps } from './variant-create.props';
// import { handbkVariantCreateAction } from '@stores/handbk-remote/handbk-remote.actions';
import { FieldLongTextBlock } from '@blocks/field-long-text';
import { handbkRemoteVariantCreateAction } from '@stores/handbk-remote/handbk-remote.actions';


export const VariantCreateComponent = ({
  handbkId,
  opened,
  closeDialog,
}: VariantCreateProps) => {
  const initialformValue = {
    label: '',
  };

  const dispatch = useAppDispatch();
  const handbkRemoteState = useAppSelector((s) => s.handbkRemote);

  const [formDirty, setFormDirty] = useState(false);
  const [sendedForm, setSendedForm] = useState(false);
  const [formValue, setFormValue] = useState(initialformValue);


  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const closeForm = () => {
    closeDialog();
    setFormValue(initialformValue);
    setSendedForm(false);
    setFormDirty(false);
  }

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    setSendedForm(true);

    const result = await dispatch(handbkRemoteVariantCreateAction({
      handbkId,
      label: formValue.label,
    }));

    if (result.type === '@@handbk-remote/variant-create/fulfilled') {
      closeForm();
    }
  }

  const setError = (fieldName: string) => {
    return sendedForm
      ? handbkRemoteState.errors && handbkRemoteState.errors[fieldName] && errorTranslate(handbkRemoteState.errors[fieldName][0])
      : '';
  }

  return (
    <Dialog
        fullWidth
        maxWidth="lg"
        open={opened}
        onClose={closeDialog}
      >
        <CardHeaderBlock
          title="Добавить варианты"
          extra={<ButtonCancelBlock onClick={closeForm} />}
        />
        <DialogContent>
          <div className="row justify-content-lg-center">
            <div className="col col-lg-11">
              <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
                <FieldLongTextBlock
                  name="label"
                  label="Значение"
                  value={formValue.label}
                  onChange={changeFormValue}
                  error={setError('label')}
                  required
                  prompt="Для добавления нескольких вариантов, опишите их с новой строки."
                />

                <ButtonsBlock>
                  <ButtonSaveBlock
                    loading={handbkRemoteState.status === 'submitting'}
                    onClick={onSubmit}
                    disabled={!formDirty}
                  />
                  <ButtonCancelBlock onClick={closeForm} />
                </ButtonsBlock>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
  );
}
