import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { createUserAction } from '@stores/user/user.actions';
import { ButtonSaveBlock } from '@blocks/button-save';
import { FieldTextBlock } from '@blocks/field-text';
import { ButtonBackBlock } from '@blocks/button-back';
import { FieldPasswordBlock } from '@blocks/field-password';
import { FieldSelectBlock } from '@blocks/field-select';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { errorTranslate } from '@helpers/error-translate.helper';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';
import { UserType } from '@type/user/user.type';

import styles from './user-create.module.scss';


const UserCreateFeature = () => {
  const [formValue, setFormValue] = useState({
    lastName: '',
    firstName: '',
    secondName: '',
    email: '',
    password: '',
    roleId: '',
  });
  const [formDirty, setFormDirty] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [user, role] = useAppSelector((state) => [state.user, state.role]);

  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    const result = await dispatch(createUserAction(formValue));

    if (result.type === '@@user/create/fulfilled') {
      navigate('/user-list/' + (result.payload as UserType).id);
    }
  }

  const setError = (fieldName: string) => {
    return user.errors && user.errors[fieldName] && errorTranslate(user.errors[fieldName][0]);
  }
  
  return (
    <div className={styles['user-create']}>
      <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
        <FieldTextBlock
          name="lastName"
          label="Фамилия"
          value={formValue.lastName}
          onChange={changeFormValue}
          error={setError('lastName')}
          required
        />

        <FieldTextBlock
          name="firstName"
          label="Имя"
          value={formValue.firstName}
          onChange={changeFormValue}
          error={setError('firstName')}
          required
        />

        <FieldTextBlock
          name="secondName"
          label="Отчество"
          value={formValue.secondName}
          onChange={changeFormValue}
          error={setError('secondName')}
        />

        <FieldTextBlock
          name="email"
          label="Электронная почта"
          value={formValue.email}
          onChange={changeFormValue}
          error={setError('email')}
          required
        />

        <FieldPasswordBlock
          name="password"
          label="Пароль"
          value={formValue.password}
          onChange={changeFormValue}
          error={setError('password')}
          required
        />

        <FieldSelectBlock
          name="roleId"
          label="Роль"
          value={formValue.roleId}
          onChange={changeFormValue}
          error={setError('roleId')}
          required
          items={role.dictionary.map((item) => ({ value: String(item.id), label: item.name }))}
        />

        <ButtonSaveBlock
          loading={user.status === 'submitting'}
          onClick={onSubmit}
          disabled={!formDirty}
        />
        <ButtonBackBlock />
      </form>
    </div>
  )
}

export { UserCreateFeature };
