import { configureStore, combineReducers } from '@reduxjs/toolkit';

import authReducer from './auth/auth.slice';
import appReducer from './app/app.slice';
import roleReducer from './role/role.slice';
import userReducer from './user/user.slice';
import handbkRemoteReducer from './handbk-remote/handbk-remote.slice';


const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  role: roleReducer,
  user: userReducer,
  handbkRemote: handbkRemoteReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
