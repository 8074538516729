import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { IconButton, TableCell, TableRow } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { ButtonsBlock } from '@blocks/buttons';

import { VariantOneProps } from './variant-one.props';


export const VariantOneComponent = ({
  handbkVariant,
  openUpdateDialog,
}: VariantOneProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: handbkVariant.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      {...attributes} style={{...style, cursor: 'auto'}}
    >
      <TableCell ref={setNodeRef} {...listeners}>
        <div style={{ cursor: 'move', opacity: 0.5, display: 'inline' }}>
          <DragHandleIcon />
        </div>
      </TableCell>
      <TableCell component="th" scope="row">{handbkVariant.id}</TableCell>
      <TableCell>{handbkVariant.label}</TableCell>
      <TableCell>
        <ButtonsBlock>
          <IconButton size="small" onClick={() => openUpdateDialog(handbkVariant)}>
            <ModeEditIcon/>
          </IconButton>
        </ButtonsBlock>
      </TableCell>
    </TableRow>
  );
}
