import { useState } from 'react';

import { FilledInput, FormControl, IconButton, InputAdornment, InputLabel } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { FormFieldBlock } from '@blocks/form-field';
import { AsteriskBlock } from '@blocks/asterisk';

import { FieldPasswordProps } from './field-password.props';


const FieldPasswordBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
}: FieldPasswordProps) => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  
  return (
    <FormFieldBlock error={error} prompt={prompt}>
      <FormControl variant="filled" fullWidth>
        <InputLabel htmlFor={name}>{label}<AsteriskBlock required={required} counted={counted} /></InputLabel>
        <FilledInput
          id={name}
          type={visiblePassword ? 'text' : 'password'}
          value={value}
          onChange={onChange}
          name={name ? name : 'password'}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => setVisiblePassword((prev) => !prev)}
              >
                {visiblePassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </FormFieldBlock>
  );
};

export { FieldPasswordBlock };
