import { SyntheticEvent, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { ButtonCancelBlock } from '@blocks/button-cancel';
import { ButtonSaveBlock } from '@blocks/button-save';
import { ButtonsBlock } from '@blocks/buttons';
import { FieldLongTextBlock } from '@blocks/field-long-text';
import { FieldTextBlock } from '@blocks/field-text';
import { errorTranslate } from '@helpers/error-translate.helper';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';

import { FormUpdateProps } from './form-update.props';
import { handbkRemoteUpdateAction } from '@stores/handbk-remote/handbk-remote.actions';


export const FormUpdateComponent = ({
  handbk,
  closeEditor,
}: FormUpdateProps) => {
  const dispatch = useAppDispatch();
  const handbkRemoteState = useAppSelector((s) => s.handbkRemote);

  const [formDirty, setFormDirty] = useState(false);
  const [sendedForm, setSendedForm] = useState(false);
  const [formValue, setFormValue] = useState(handbk);


  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const closeForm = () => {
    setFormValue(handbk);
    setSendedForm(false);
    setFormDirty(false);
    closeEditor();
  }

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();
    
    setSendedForm(true);

    const result = await dispatch(handbkRemoteUpdateAction({
      id: formValue.id,
      name: formValue.name,
      description: formValue.description,
    }));

    if (result.type === '@@handbk-remote/update/fulfilled') {
      closeForm();
    }
  }

  const setError = (fieldName: string) => {
    return sendedForm
      ? handbkRemoteState.errors && handbkRemoteState.errors[fieldName] && errorTranslate(handbkRemoteState.errors[fieldName][0])
      : '';
  }
  return (
    <div className="row justify-content-lg-center">
      <div className="col col-lg-6">
        <form
          style={{padding: '24px 0'}}
          onSubmit={(e: SyntheticEvent) => onSubmit(e)}
        >
          <FieldTextBlock
            name="name"
            label="Название"
            value={formValue.name}
            onChange={changeFormValue}
            error={setError('name')}
            required
          />
          <FieldLongTextBlock
            name="description"
            label="Описание"
            value={formValue.description}
            onChange={changeFormValue}
            error={setError('description')}
          />
          <ButtonsBlock>
            <ButtonSaveBlock
              loading={handbkRemoteState.status === 'submitting'}
              onClick={onSubmit}
              disabled={!formDirty}
            />
            <ButtonCancelBlock onClick={() => closeForm()} />
          </ButtonsBlock>
        </form>
      </div>
    </div>
  );
}
