import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BackendErrorsType } from '@type/shared/backend-errors.type';

import { HandbkRemoteStateInterface } from '@type/handbk-remote/handbk-remote-state.interface';
import { handbkRemoteCreateAction, handbkRemoteListAction, handbkRemoteSortableAction, handbkRemoteUpdateAction, handbkRemoteVariantCreateAction, handbkRemoteVariantUpdateAction } from './handbk-remote.actions';
import { HandbkRemoteCreateResponse } from '@type/handbk-remote/handbk-remote-create.res';
import { HandbkRemoteListResponse } from '@type/handbk-remote/handbk-remote-list.res';
import { HandbkRemoteUpdateResponse } from '@type/handbk-remote/handbk-remote-update.res';
import { HandbkRemoteVariantCreateResponse } from '@type/handbk-remote/handbk-remote-variant-create.res';
import { HandbkRemoteVariantUpdateResponse } from '@type/handbk-remote/handbk-remote-variant-update.res';


const initialState: HandbkRemoteStateInterface = {
  status: 'idle',
  list: [],
  variants: [],
  current: null,
  errors: null,
};

const handbkRemoteSlice = createSlice({
  name: '@@handbk-remote',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handbkRemoteCreateAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(handbkRemoteCreateAction.fulfilled, (
        state,
        action: PayloadAction<HandbkRemoteCreateResponse>,
      ) => {
        const handbk = action.payload.handbk;

        state.list.unshift(handbk);

        state.status = 'idle';
      })
      .addCase(handbkRemoteCreateAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      })

      .addCase(handbkRemoteListAction.pending, (state) => {
        state.status = 'loading';
        state.errors = null;
      })
      .addCase(handbkRemoteListAction.fulfilled, (
        state,
        action: PayloadAction<HandbkRemoteListResponse>,
      ) => {
        state.list = action.payload.list;
        state.variants = action.payload.variants;

        state.status = 'idle';
      })
      .addCase(handbkRemoteListAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      })
      
      .addCase(handbkRemoteUpdateAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(handbkRemoteUpdateAction.fulfilled, (
        state,
        action: PayloadAction<HandbkRemoteUpdateResponse>,
      ) => {
        const handbk = action.payload.handbk;

        state.list = state.list.map((h) => {
          return h.id === handbk.id
            ? { ...h, name: handbk.name, description: handbk.description }
            : h
        });

        state.status = 'idle';
      })
      .addCase(handbkRemoteUpdateAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      })

      .addCase(handbkRemoteSortableAction.pending, (state, action) => {
        state.status = 'submitting';
        const payload = action.meta.arg;
        const handbk = state.list.find((h) => h.id === payload.id);

        if (!handbk) return;

        handbk.sortable = payload.sortable;
        state.errors = null;
      })
      .addCase(handbkRemoteSortableAction.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(handbkRemoteSortableAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      })
      
      .addCase(handbkRemoteVariantCreateAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(handbkRemoteVariantCreateAction.fulfilled, (
        state,
        action: PayloadAction<HandbkRemoteVariantCreateResponse>
      ) => {
        state.status = 'idle';

        const { handbkId, variants } = action.payload;
        const handbk = state.list.find((h) => h.id === handbkId);

        if (!handbk) return;

        handbk.sortable = [...handbk.sortable, ...variants.map((v) => v.id)];
        state.variants = [...state.variants, ...variants];
      })
      .addCase(handbkRemoteVariantCreateAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      })
      
      .addCase(handbkRemoteVariantUpdateAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(handbkRemoteVariantUpdateAction.fulfilled, (
        state,
        action: PayloadAction<HandbkRemoteVariantUpdateResponse>
      ) => {
        state.status = 'idle';

        const { handbkVariant } = action.payload;

        const oldVariant = state.variants.find((v) => v.id === handbkVariant.id);

        if (!oldVariant) return;

        oldVariant.label = handbkVariant.label;
      })
      .addCase(handbkRemoteVariantUpdateAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      });
  },
});

export default handbkRemoteSlice.reducer;
