import { SyntheticEvent, useState } from 'react';

import { updateUserAction } from '@stores/user/user.actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { errorTranslate } from '@helpers/error-translate.helper';
import { FieldTextBlock } from '@blocks/field-text';
import { FieldSelectBlock } from '@blocks/field-select';
import { ButtonCancelBlock } from '@blocks/button-cancel';
import { ButtonSaveBlock } from '@blocks/button-save';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';

import { UserUpdateProps } from './user-update.props';
import styles from './user-update.module.scss';
import { FieldBooleanBlock } from '@blocks/field-boolean';


const UserUpdateFeature = ({ onDone }: UserUpdateProps) => {
  const dispatch = useAppDispatch();
  const [user, role] = useAppSelector((state) => [state.user, state.role]);
  const [formValue, setFormValue] = useState({
    id: user.current!.id,
    firstName: user.current!.firstName,
    secondName: user.current!.secondName,
    lastName: user.current!.lastName,
    email: user.current!.email,
    blocked: user.current!.blocked,
    roleId: String(user.current!.role.id),
  });
  const [formDirty, setFormDirty] = useState(false);

  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    const result = await dispatch(updateUserAction(formValue));

    if (result.type === '@@user/update/fulfilled') {
      onDone();
    }
  }

  const setError = (fieldName: string) => {
    return user.errors && user.errors[fieldName] && errorTranslate(user.errors[fieldName][0]);
  }

  return (
    <div className="row justify-content-lg-center">
      <div className="col col-lg-6">
        <div className={styles['user-update']}>
          <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
          <FieldTextBlock
            name="lastName"
            label="Фамилия"
            value={formValue.lastName}
            onChange={changeFormValue}
            error={setError('lastName')}
            required
          />

          <FieldTextBlock
            name="firstName"
            label="Имя"
            value={formValue.firstName}
            onChange={changeFormValue}
            error={setError('firstName')}
            required
          />

          <FieldTextBlock
            name="secondName"
            label="Отчество"
            value={formValue.secondName}
            onChange={changeFormValue}
            error={setError('secondName')}
          />

          <FieldTextBlock
            name="email"
            label="Электронная почта"
            value={formValue.email}
            onChange={changeFormValue}
            error={setError('email')}
            required
          />

          <FieldSelectBlock
            name="roleId"
            label="Роль"
            value={formValue.roleId}
            onChange={changeFormValue}
            error={setError('roleId')}
            required
            items={role.dictionary.map((item) => ({ value: String(item.id), label: item.name }))}
          />

          <FieldBooleanBlock
            name="blocked"
            label="Заблокирован"
            value={formValue.blocked}
            onChange={changeFormValue}
            error={setError('blocked')}
          />

        <ButtonSaveBlock
          loading={user.status === 'submitting'}
          onClick={onSubmit}
          disabled={!formDirty}
        />
        <ButtonCancelBlock onClick={() => onDone()} />
          </form>
        </div>
      </div>
    </div>
  );
};

export { UserUpdateFeature };
