
import { Button } from '@mui/material';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

import { ButtonCancelProps } from './button-cancel.props';


const ButtonCancelBlock = ({ onClick, label }: ButtonCancelProps) => {
  return (
    <Button
      onClick={onClick}
      size="large"
      startIcon={<DoDisturbIcon />}
      variant="outlined"
    >
      {label || 'Отмена'}
    </Button>
  );
};

export { ButtonCancelBlock };
