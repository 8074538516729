import { createSlice } from '@reduxjs/toolkit';

import { BackendErrorsType } from '@type/shared/backend-errors.type';
import { UserStateInterface } from '@type/user/user-state.interface';
import { changeUserPasswordAction, createUserAction, getUserDictionaryAction, getUserListAction, getUserOneAction, updateUserAction } from './user.actions';


const initialState: UserStateInterface = {
  list: [],
  current: null,
  dictionary: [],
  status: 'idle',
  errors: null,
}

const userSlice = createSlice({
  name: '@@user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserListAction.pending, (state) => {
        state.status = 'loading';
        state.errors = null;
        state.list = [];
      })
      .addCase(getUserListAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(getUserListAction.rejected, (state) => {
        state.status = 'idle';
      })

      .addCase(getUserDictionaryAction.pending, (state) => {
        state.status = 'loading';
        state.errors = null;
        state.list = [];
      })
      .addCase(getUserDictionaryAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.dictionary = action.payload;
      })
      .addCase(getUserDictionaryAction.rejected, (state) => {
        state.status = 'idle';
      })

      .addCase(getUserOneAction.pending, (state) => {
        state.status = 'loading';
        state.errors = null;
        state.current = null;
      })
      .addCase(getUserOneAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.current = action.payload;
      })
      .addCase(getUserOneAction.rejected, (state) => {
        state.status = 'idle';
      })

      .addCase(createUserAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(createUserAction.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(createUserAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      })

      .addCase(updateUserAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(updateUserAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.current = action.payload
      })
      .addCase(updateUserAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      })
      
      .addCase(changeUserPasswordAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(changeUserPasswordAction.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(changeUserPasswordAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      });
  },
});

export default userSlice.reducer;
