import SaveIcon from '@mui/icons-material/Save';

import { ButtonSaveProps } from './button-save.props';
import { LoadingButton } from '@mui/lab';


const ButtonSaveBlock = ({
  onClick,
  loading,
  disabled,
  size,
  variant,
}: ButtonSaveProps) => {
  return (
    <LoadingButton
      loading={loading}
      type="submit"
      onClick={onClick}
      size={size || 'large'}
      startIcon={<SaveIcon />}
      variant={variant || 'contained'}
      color="primary"
      disabled={disabled}
    >
      Сохранить
    </LoadingButton>
  );
};

export { ButtonSaveBlock };
