import { ButtonsProps } from './buttons.props';
import styles from './buttons.module.scss';


export const ButtonsBlock = (
  { children }: ButtonsProps,
) => {
  return (
    <div className={styles['buttons']}>{children}</div>
  )
}