import React, { FC } from 'react';

import { AccountCircle } from '@mui/icons-material';
import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';

import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { signoutAction } from '@stores/auth/auth.actions';

import { TopBarProps } from './top-bar.props';
import styles from './top-bar.module.scss';


const TopBarFeature: FC<TopBarProps> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = () => {
    dispatch(signoutAction());
    handleClose();
  }
  
  return (
    <div className={styles['top-bar']}>
      <AppBar position="static">
        <Toolbar>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className={styles['top-bar__container']}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    AG:DCS
                  </Typography>
                  <div className={styles['top-bar__right-wrapper']}>
                    <div className={styles['top-bar__user-name']}>{auth.user!.firstName} {auth.user!.secondName} {auth.user!.lastName}</div>
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleSignout}>Выйти</MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export { TopBarFeature };
