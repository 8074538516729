import { Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { dateTranslate } from '@helpers/date-translate.helper';
import { accessTranslate } from '@helpers/access-translate.helper';
import { useAppSelector } from '@hooks/redux.hooks';

import styles from './role-detail.module.scss';
import { booleanTranslate } from '@helpers/boolean-translate.helper';


const RoleDetailFeature = () => {
  const role = useAppSelector((state) => state.role);

  return (
    <div className={styles['role-detail']}>
      <div className={styles['role-detail__summary']}>
        <TableContainer>
          <Table aria-label="role-detail">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Название</TableCell>
                <TableCell>Конфигурация справочников</TableCell>
                <TableCell>Дата создания</TableCell>
                <TableCell>Дата обновления</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {role.current!.id}
                  </TableCell>
                  <TableCell>{role.current!.name}</TableCell>
                  <TableCell>{booleanTranslate(role.current!.HANDBK_REMOTE_ACCESS)}</TableCell>
                  <TableCell>{dateTranslate(role.current!.createdAt)}</TableCell>
                  <TableCell>{dateTranslate(role.current!.updatedAt)}</TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* <div className={styles['role-detail__header-accesses']}>
        <Typography variant="h5">Матрица доступа к настройкам пользователя</Typography>
      </div> */}
      <Divider />
      <TableContainer style={{ marginBottom: '20px' }}>
        <Table aria-label="role-detail">
          <TableHead>
            <TableRow>
              <TableCell>Таблица</TableCell>
              <TableCell>Создание</TableCell>
              <TableCell>Чтение</TableCell>
              <TableCell>Обновление</TableCell>
              <TableCell>Удаление</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Роли
              </TableCell>
              <TableCell>{accessTranslate(role.current!.ROLE_CREATE)}</TableCell>
              <TableCell>{accessTranslate(role.current!.ROLE_READ)}</TableCell>
              <TableCell>{accessTranslate(role.current!.ROLE_UPDATE)}</TableCell>
              <TableCell>{accessTranslate(role.current!.ROLE_DELETE)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Пользователи
              </TableCell>
              <TableCell>{accessTranslate(role.current!.USER_CREATE)}</TableCell>
              <TableCell>{accessTranslate(role.current!.USER_READ)}</TableCell>
              <TableCell>{accessTranslate(role.current!.USER_UPDATE)}</TableCell>
              <TableCell>{accessTranslate(role.current!.USER_DELETE)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export { RoleDetailFeature };
